<template>
  <gf-base>
    <gf-card title="Sales Orders" subtitle="List of Sales Order">
      <template #toolbar>
        <gf-button type="primary" @click="newSalesOrder">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <circle fill="#000000" cx="9" cy="15" r="6"/>
                  <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          New Record
        </gf-button>
      </template>
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search" placeholder="Search by name or company code ..." v-model="search"></el-input>
        <span class="f-13 mr-2">Status :</span>
        <el-select size="medium" class="mr-4" v-model="status" @change="statusChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="1" label="Draft"></el-option>
          <el-option :value="2" label="Active"></el-option>
          <el-option :value="3" label="Finalized"></el-option>
          <el-option :value="4" label="Fulfilled"></el-option>
          <el-option :value="5" label="Void"></el-option>
          <el-option :value="6" label="Deleted"></el-option>
        </el-select>
      </template>
      <gf-table :data="salesOrders" :column-filter="columnFilter" @row-click="readSalesOrder" v-loading="loading">
        <el-table-column v-if="columnFilter[0].show" label="Order No." min-width="110" prop="so_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[1].show" label="Company Name" min-width="200" prop="customer.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[2].show" label="Status" min-width="130">
          <template slot-scope="scope">
            <gf-label inline pill type="dark" v-if="scope.row.status === 1">Draft</gf-label>
            <gf-label inline pill type="primary" v-if="scope.row.status === 2">Active</gf-label>
            <gf-label inline pill type="success" v-if="scope.row.status === 3">Finalized</gf-label>
            <gf-label inline pill type="info" v-if="scope.row.status === 4">Fulfilled</gf-label>
            <gf-label inline pill type="warning" v-if="scope.row.status === 5">Void</gf-label>
            <gf-label inline pill type="danger" v-if="scope.row.status === 6">Deleted</gf-label>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[3].show" label="Packed" min-width="110" align="center">
          <template slot-scope="slot">
            <div class="circle" :class="[slot.row.packed === 1 ? 'circle--half' : '', slot.row.packed === 2 ? 'circle--full' : '']"></div>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[4].show" label="Fulfilled" min-width="120" align="center">
          <template slot-scope="slot">
            <div class="circle" :class="[slot.row.fulfilled === 1 ? 'circle--half' : '', slot.row.fulfilled === 2 ? 'circle--full' : '']"></div>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[5].show" label="Invoiced" min-width="120" align="center">
          <template slot-scope="slot">
            <div class="circle" :class="[slot.row.invoiced === 1 ? 'circle--half' : '', slot.row.invoiced === 2 ? 'circle--full' : '']"></div>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[6].show" label="Paid" min-width="80" align="center">
          <template slot-scope="slot">
            <div class="circle" :class="[slot.row.paid === 1 ? 'circle--half' : '', slot.row.paid === 2 ? 'circle--full' : '']"></div>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[7].show" label="Returning" min-width="150">
        </el-table-column>
        <el-table-column v-if="columnFilter[8].show" label="Returned" min-width="150">
        </el-table-column>
        <el-table-column v-if="columnFilter[9].show" label="Order Total" min-width="150">
          <template slot-scope="slot">
            {{ $NumberFormat(slot.row.total) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[10].show" label="Assignee Name" min-width="160">
          <template slot-scope="slot">
            {{ slot.row.assigned_to.fname }}  {{ slot.row.assigned_to.lname }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[11].show" label="Ship From" min-width="150" prop="ship_from.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[12].show" label="Email" min-width="150" prop="email">
        </el-table-column>
        <el-table-column v-if="columnFilter[13].show" label="Reference" min-width="150" prop="reference">
        </el-table-column>
        <el-table-column v-if="columnFilter[14].show" label="Issued At" min-width="140">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.issue_date) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[15].show" label="Created Date" min-width="150">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[16].show" label="Ship by Date" min-width="150">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.shipment_date) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[17].show" label="Last Updated" min-width="150">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[18].show" label="Notes" min-width="150" prop="message">
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getSalesOrders"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<style lang="scss" scoped>
.circle {
  width: 18px;
  height: 18px;
  border: 2px solid #585d64;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;

  &--half {
    background: linear-gradient(90deg, #585d64 50%, #fff 50%);
  }

  &--full {
    background: #585d64;
  }
}
</style>

<script>
// Services
import SalesOrderService from '@/services/v1/SalesOrder'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      // ui
      loading: false,

      columnFilter: [
        { label: 'Order No.', show: true },
        { label: 'Company Name', show: true },
        { label: 'Status', show: true },
        { label: 'Packed', show: true },
        { label: 'Fulfilled', show: true },
        { label: 'Invoiced', show: true },
        { label: 'Paid', show: true },
        { label: 'Returning', show: false },
        { label: 'Returned', show: false },
        { label: 'Order Total', show: true },
        { label: 'Assignee Name', show: false },
        { label: 'Ship From', show: false },
        { label: 'Email', show: false },
        { label: 'Reference', show: false },
        { label: 'Issued At', show: false },
        { label: 'Created Date', show: true },
        { label: 'Ship by Date', show: true },
        { label: 'Last Updated', show: true },
        { label: 'Notes', show: false }
      ],
      search: '',
      status: null,

      filter: {},
      isApprover: false,
      salesOrders: [],

      // pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.pagination.page.number = 1

      this.getSalesOrders()
    }, 400)
  },
  methods: {
    newSalesOrder () {
      this.$router.push({ name: 'order-new' })
    },
    readSalesOrder (row) {
      this.$router.push({ name: 'order-view', params: { id: row.id } })
    },

    // filter
    statusChange () {
      // Reset to page 1
      this.pagination.page.number = 1

      if (this.status !== null) {
        this.filter['so.status'] = { '=': this.status }
        this.getSalesOrders()
        return
      }

      delete this.filter['so.status']
      this.getSalesOrders()
    },

    // service fetch
    async getSalesOrders () {
      try {
        this.loading = true
        const soService = new SalesOrderService()
        const result = await soService.list(this.pagination.page, null, this.filter, null)
        this.salesOrders = result.data.rows

        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Sales Order' },
      { title: 'Order' }
    ])

    this.getSalesOrders()
  }
}
</script>
